import React, { Suspense, useCallback, useEffect, useState } from 'react';

import { LocalizedRoutes } from './router/routes';
import { useAnchorScroller } from './utils/anchor-scroller/anchor-scroller-hook';
import { Spinner } from './components/spinner/Spinner';

import styles from './styles/App.module.scss';

const MOBILE_BREAKPOINT = 820;
export const COOKIES_KEY_LOCAL_STORAGE = 'cookieUsageConfirmed';

function App() {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < MOBILE_BREAKPOINT);
  const [shouldShowCookiesWarning, setShouldShowCookiesWarning] = useState<boolean>(false);

  const handleWindowResize = useCallback(() => {
    if (window.innerWidth < MOBILE_BREAKPOINT && !isMobile) {
      setIsMobile(true);
    } else if (window.innerWidth >= MOBILE_BREAKPOINT && isMobile) {
      setIsMobile(false);
    }
  }, [isMobile]);

  useEffect(() => {
    setShouldShowCookiesWarning(window.localStorage.getItem(COOKIES_KEY_LOCAL_STORAGE) === null);
    window.addEventListener('resize', handleWindowResize, false);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  const hideCookiesWarning = () => {
    if (shouldShowCookiesWarning) {
      localStorage.setItem(COOKIES_KEY_LOCAL_STORAGE, 'true');
    }
    setShouldShowCookiesWarning(false);
  };

  useAnchorScroller();

  return (
    <div className={ `${ styles.App }` }>
      <Suspense fallback={ <Spinner/> }>
        <LocalizedRoutes
          isMobile={ isMobile }
          shouldShowCookiesWarning={ shouldShowCookiesWarning }
          hideCookiesWarning={ hideCookiesWarning }
        />
      </Suspense>
    </div>
  );
}

export default App;
