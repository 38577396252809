import React from 'react';

import styles from './mobile-button.module.scss'

export function MobileButton(props: { onClick: () => void }) {
  return (
    <div onClick={props.onClick} className={styles.mobileButton}>
      <span />
      <span />
      <span />
    </div>
  )
}