import React, { useState } from 'react';

import { HeaderNavButton } from './nav-button/HeaderNavButton';

import styles from './Header.module.scss';
import logo from './logo-white.svg';
import { Link } from 'react-router-dom';
import { MobileButton } from './mobile-button/mobile-button';
import { useTranslation } from 'react-i18next';
import { IndexSegmentAnchors } from '../../models/index-segment-anchors';
import { TranslationNamespace } from '../../models/translation-namespace';

export const Header = (props: { isMobile: boolean }): JSX.Element => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const handleMobileClick = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <header className={ `${ styles.header } segment` }>
      <Link to='/'>
        <img src={ logo } className={ styles['header-logo'] } alt='logo'/>
      </Link>
      { !props.isMobile ? <Navigation/> : <MobileButton onClick={ handleMobileClick }/> }

      { props.isMobile && isMenuOpened &&
      <>
        <div className={ styles['mobile-nav-holder'] } onClick={ handleMobileClick }>
          <Navigation/>
        </div>
        <div
          className={ styles['mobile-nav-overlay'] }
          onClick={ handleMobileClick }
        />
      </>
      }
    </header>
  );
};

const Navigation = () => {
  const { t } = useTranslation(TranslationNamespace.INDEX_PAGE);

  return (
    <div className={ styles['nav-holder'] }>
      <HeaderNavButton href='/' anchor={ IndexSegmentAnchors.COMPANY }>{ t('header.company') }</HeaderNavButton>
      <HeaderNavButton href='/' anchor={ IndexSegmentAnchors.SERVICES }>{ t('header.services') }</HeaderNavButton>
      <HeaderNavButton href='/' anchor={ IndexSegmentAnchors.FEATURES }>{ t('header.features') }</HeaderNavButton>
      <HeaderNavButton href='/' anchor={ IndexSegmentAnchors.CONTACTS }>{ t('header.contacts') }</HeaderNavButton>
      <HeaderNavButton href='https://u24.gov.ua' isExternal>#standwithUkraine</HeaderNavButton>
    </div>
  );
};
