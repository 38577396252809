import 'react-app-polyfill/ie9';
import * as React from 'react';
import { hydrate, render } from 'react-dom';
import './styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import smoothscroll from 'smoothscroll-polyfill';

import './i18n';
import { BrowserRouter as Router } from 'react-router-dom';

const root = document.getElementById('root');

smoothscroll.polyfill();

if (root?.hasChildNodes()) {
  hydrate(<Router><App /></Router>, root);
} else {
  render(<Router><App /></Router>, root);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
