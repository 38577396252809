import React from 'react';
import { Link } from 'react-router-dom';

import { AnchorScrollerMode, scrollToHash } from './anchor-scroller-helpers';
import { IndexSegmentAnchors } from '../../models/index-segment-anchors';
import { DocsSegmentAnchors } from '../../models/docs-segment-anchors';

interface IAnchorScrollerProps {
  anchor: IndexSegmentAnchors | DocsSegmentAnchors;
  mode?: AnchorScrollerMode,
  offset?: number;
  className?: string;
  children: React.ReactNode;
  href?: string;
}

export const AnchorScroller = (props: IAnchorScrollerProps) => {

  const handleClick = () => {
    setTimeout(() => {
      requestAnimationFrame(() => {
        scrollToHash(props.anchor, props.mode, props.offset);
      });
    });
  };

  return (
    <Link
      className={ props.className || '' }
      to={ `${ props.href || '' }#${ props.anchor }` }
      onClick={ handleClick }
    >
      { props.children }
    </Link>
  );
};
