import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Footer.module.scss';
import visaVerif from './logos/visa-verified.svg';
import visa from './logos/visa.svg';
import mc from './logos/mc.svg';
import mcSc from './logos/mc-sc.svg';
import pci from './logos/pci.svg';
import { useTranslation } from 'react-i18next';
import { TranslationNamespace } from '../../models/translation-namespace';

export function Footer(props: { shouldShowCookiesWarning: boolean }) {
  const { t } = useTranslation(TranslationNamespace.INDEX_PAGE);

  return (
    <div className={`${styles.Footer} ${props.shouldShowCookiesWarning ? styles['with-cookies'] : ''}`}>
      <div className={styles['logos-row']}>
        <img src={visaVerif} alt='visaVerif' className={styles['visaVerif']}/>
        <img src={visa} alt='visa' className={styles['visa']}/>
        <img src={mc} alt='mc' className={styles['mc']}/>
        <img src={mcSc} alt='mcSc' className={styles['mcSc']}/>
        <img src={pci} alt='pci' className={styles['pci']}/>
      </div>
      <div className={styles['links-row']}>
        <div className={styles['left-link']}>
          <Link to='/terms'>{ t('footer.termsAndConditions') }</Link>
        </div>
        <div className={styles['right-link']}>
          <Link to='/privacy'>{ t('footer.privacyPolicy') }</Link>
        </div>
      </div>
      <div className={styles['info-row']}>
        { t('footer.rightsReserved', { year: new Date().getFullYear()}) }
      </div>
    </div>
  );
}
