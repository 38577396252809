import React from 'react';

import { IndexSegmentAnchors } from '../../../models/index-segment-anchors';
import { AnchorScroller } from '../../../utils/anchor-scroller/anchor-scroller';

import styles from './HeaderNavButton.module.scss';

interface TransparentButtonProps {
  anchor?: IndexSegmentAnchors;
  children: React.ReactNode;
  href?: string;
  isExternal?: boolean;
}

export function HeaderNavButton(props: TransparentButtonProps) {
  if (props.isExternal && props.href) {
    return (
      <a className={ styles['TransparentButton'] } href={ props.href } rel='noreferrer noopener' target='_blank'>
        { props.children }
      </a>
    )
  }

  if (props.anchor) {
    return (
      <AnchorScroller className={ styles['TransparentButton'] } anchor={ props.anchor } href={ props.href }>
        { props.children }
      </AnchorScroller>
    );
  }

  return <></>;
}
