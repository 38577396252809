import React from 'react';

import './Spinner.scss';

export const Spinner = () => {
  return (
    <div className='spinner-layout'>
      <div className='spinner'>
        <div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/>
      </div>
    </div>
  );
};
