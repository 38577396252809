import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { debounce, getBestAnchorGivenScrollLocation } from './anchor-scroller-helpers';

export const useAnchorScroller = () => {
  const history = useHistory();

  const handleWindowScroll = useCallback(() => {
    const segments = document.querySelectorAll('*[data-segment]') as NodeListOf<HTMLAnchorElement>;
    const bestId = getBestAnchorGivenScrollLocation(segments);

    history.replace(`#${ bestId }`);
  }, [history]);

  useEffect(() => {
    const debouncedFunction = debounce(() => handleWindowScroll(), 35);
    window.addEventListener('scroll', debouncedFunction);
    return () => {
      window.removeEventListener('scroll', debouncedFunction);
    };
  }, [handleWindowScroll, history]);
};
