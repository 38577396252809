import { Helmet } from 'react-helmet';
import React from 'react';

export function NoRobotsHelmet() {
  return (
    <Helmet>
      <meta charSet='utf-8'/>
      <meta name='description' content='TNXHUB® - #1 Payment Gateway as a service solution'/>
      <title>TNXHUB® - a global payment processing hub</title>

      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex"/>
    </Helmet>
  )
}
