export enum AnchorScrollerMode {
  SMOOTH = 'smooth',
  AUTO = 'auto',
}

export const scrollToHash = (
  id: string,
  scrollMode: AnchorScrollerMode = AnchorScrollerMode.SMOOTH,
  scrollOffset?: number
) => {
  const targetSegment = document.querySelector(`*[data-segment='${ id }']`);
  if (!targetSegment) {
    return;
  }

  const offset: number = scrollOffset || parseInt(targetSegment.getAttribute('data-anchor-offset') || '0');
  if (!targetSegment) {
    return;
  }

  const offsetTop = targetSegment.getBoundingClientRect().top + window.pageYOffset;
  window.scroll({
    top: offsetTop - offset,
    behavior: scrollMode,
  });
};

export const getScrollTop = () => {
  return document.body.scrollTop || document.documentElement.scrollTop;
};

export const getElementOffset = (element: HTMLElement): { top: number, bottom: number } => {
  const scrollTop = getScrollTop();
  const { top, bottom } = element.getBoundingClientRect();
  return {
    top: Math.floor(top + scrollTop),
    bottom: Math.floor(bottom + scrollTop),
  };
};

export const doesElementContainScrollTop = (element: HTMLElement, extraOffset: number = 0): boolean => {
  const scrollTop = getScrollTop();
  const offsetTop = getElementOffset(element).top;
  return scrollTop + extraOffset >= offsetTop && scrollTop + extraOffset < offsetTop + element.offsetHeight;
};

export const checkLocationRelevance = (element1: HTMLElement, element2: HTMLElement): boolean => {
  const elementOffset1 = getElementOffset(element1);
  const elementOffset2 = getElementOffset(element2);
  if (elementOffset1.top === elementOffset2.top) {
    if (elementOffset1.bottom === elementOffset2.bottom) {
      return element1 < element2;
    }
    return elementOffset2.bottom < elementOffset1.bottom;
  }
  return elementOffset2.top > elementOffset1.top;
};

export const checkElementRelevance = (element1: HTMLElement, element2: HTMLElement): boolean => {
  if (element1.contains(element2)) {
    return true;
  } else if (!element2.contains(element1) && checkLocationRelevance(element1, element2)) {
    return true;
  }
  return false;
};

export const getBestAnchorGivenScrollLocation = (anchoredSegments: NodeListOf<HTMLElement>) => {
  let matchedAnchor: string = '';
  let matchedSegment: HTMLElement;

  anchoredSegments.forEach((segment: HTMLElement) => {
    const targetAnchor = segment.getAttribute('data-segment');
    const offset: number = parseInt(segment.getAttribute('data-anchor-offset') || '0');
    if (doesElementContainScrollTop(segment, offset)) {
      if (!matchedSegment || checkElementRelevance(matchedSegment, segment)) {
        matchedSegment = segment;
        matchedAnchor = targetAnchor as string;
      }
    }
  });
  return matchedAnchor;
};

export const debounce = (func: (...data: any) => void, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return (...args: any[]) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };
};
