import { Helmet } from 'react-helmet';
import React from 'react';

export function IndexPageHelmet() {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <meta name='description' content='TNXHUB® - payment solution for high-risk and low-risk merchants' />
      <title>TNXHUB® - #1 Payment Gateway as a service solution</title>
      <meta name="robots" content="index follow" />
      <meta name="googlebot" content="index follow"/>
      <meta charSet='UTF-8'/>
      <meta name='keywords' content='payments, payment service provider, accepting payments, payouts, multi-acquiring, smart routing and cascading'/>
      <meta name='description' content='TNXHUB® - payment solution for high-risk and low-risk merchants'/>

      <meta property='og:type' content='website'/>
      <meta property='og:site_name' content='TNXHUB® - #1 Payment Gateway as a service solution'/>
      <meta property='og:title' content='TNXHUB® - #1 Payment Gateway as a service solution'/>
      <meta property='og:description' content='TNXHUB® - payment solution for high-risk and low-risk merchants'/>
      <meta property='og:url' content='https://tnxhub.com'/> {/* TODO: change site url */}
      <meta property='og:locale' content='en_EN'/>
      <meta property='og:image' content='/favicon/android-icon-192x192.png'/>
      <meta property='og:image:width' content='568'/>
      <meta property='og:image:height' content='568'/>

      <meta name='twitter:card' content='summary_large_image'/>
      <meta name='twitter:title' content='TNXHUB® - #1 Payment Gateway as a service solution'/>
      <meta name='twitter:description' content='TNXHUB® - payment solution for high-risk and low-risk merchants'/>
      <meta name='twitter:image:src /'
            content='/favicon/android-icon-192x192.png'/>
      <meta name='twitter:url' content='https://tnxhub.com'/>
      <meta name='twitter:domain' content='tnxhub.com'/>
      <meta name='twitter:site' content='@'/>
      <meta name='twitter:creator' content='@TNXHUB® team'/>
    </Helmet>
  )
}
