import { useEffect } from 'react';

const TNXHUB_DOCS_URL = 'https://docs.tnxhub.com'

export const RedirectToTnxhubDocs = () => {
  useEffect(() => {
    window.location.replace(TNXHUB_DOCS_URL)
  }, []);

  return <div />;
};
