import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import { IndexPageHelmet } from '../helmets/IndexPageHelmet';
import { NoRobotsHelmet } from '../helmets/NoRobotsHelmet';

import i18next from 'i18next';
import { getBrowserLanguage, SUPPORTED_LANGUAGES } from '../utils/helpers/lang-helpers';
import { Header } from '../components/header/Header';
import { Footer } from '../components/footer/Footer';

import styles from '../styles/App.module.scss';
import { RedirectToTnxhubDocs } from '../pages/redirect-to-tnxhub-docs';

const LOCALE_ITEM_NAME = 'selectedLocale';

const IndexPage = React.lazy(() => import('../pages/index/IndexPage'));
const TermsPage = React.lazy(() => import('../pages/terms-and-conditions/TermsPage'));
const PrivacyPage = React.lazy(() => import('../pages/privacy-policy/PrivacyPage'));

export interface IPageProps {
  isMobile: boolean;
  shouldShowCookiesWarning: boolean;
  hideCookiesWarning: () => void;
}

export const LocalizedRoutes = (props: IPageProps) => {
  useEffect(() => {
    const localeFromLocalStorage = localStorage.getItem(LOCALE_ITEM_NAME) as string;
    if (!SUPPORTED_LANGUAGES.includes(localeFromLocalStorage)) {
      localStorage.removeItem(LOCALE_ITEM_NAME);
    }
  }, []);

  return (
    <Switch>
      <Route path={ `/:locale(${ SUPPORTED_LANGUAGES.join('|') })` }>
        <UrlLanguageHandler>
          <Routes { ...props } />
        </UrlLanguageHandler>
      </Route>

      <Route exact path='*' render={ (props) => (
        <Redirect
          to={ `/${ localStorage.getItem(LOCALE_ITEM_NAME) || getBrowserLanguage() || 'en' }${ props.location.pathname }` }/>
      ) }>
      </Route>
    </Switch>
  );
};

const UrlLanguageHandler = ({ children }: { children: React.ReactNode }) => {
  const { locale } = useParams<{ locale: string }>();

  useEffect(() => {
    if (i18next.language === locale) {
      return;
    }
    localStorage.setItem(LOCALE_ITEM_NAME, locale);
    i18next.changeLanguage(locale);
  }, [locale]);

  return <>{ children }</>;
};

const Routes = (props: IPageProps) => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={ `${ path }/terms` }>
        <div className={ styles.wrapper }>
          <NoRobotsHelmet/>
          <Header isMobile={ props.isMobile }/>
          <TermsPage { ...props } />
          <Footer shouldShowCookiesWarning={ props.shouldShowCookiesWarning }/>
        </div>
      </Route>

      <Route path={ `${ path }/privacy` }>
        <div className={ styles.wrapper }>
          <NoRobotsHelmet/>
          <Header isMobile={ props.isMobile }/>
          <PrivacyPage { ...props } />
          <Footer shouldShowCookiesWarning={ props.shouldShowCookiesWarning }/>
        </div>
      </Route>

      <Route path={ path } exact>
        <div className={ styles.wrapper }>
          <IndexPageHelmet/>
          <Header isMobile={ props.isMobile }/>
          <IndexPage { ...props } />
          <Footer shouldShowCookiesWarning={ props.shouldShowCookiesWarning }/>
        </div>
      </Route>

      <Route path={ `${ path }/docs` }>
        <NoRobotsHelmet />
        <RedirectToTnxhubDocs />
      </Route>

      <Redirect to={ `${ url }/` }/>
    </Switch>
  );
};
